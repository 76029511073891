import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

function DoNotSellMyPersonalInformation() {
  const [optoutsalerequest, setIsCheckedOptOutSale] = useState(false);
  const [optoutsharerequest, setIsCheckedOptOutShare] = useState(false);

  const optOutSaleRequest = () => {
    setIsCheckedOptOutSale(!optoutsalerequest);
  };

  const optOutShareRequest = () => {
    setIsCheckedOptOutShare(!optoutsharerequest);
  };


  const [selectResideState, setResideState] = useState("")

  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [street_address, setAddress] = useState("")
  const [unit, setUnit] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("Country of Residence")
  //const [message, setMessage] = useState("")
  const [zip, setZip] = useState("")
  const [request, setRequest] = useState("ccpa_do_not_sell_or_share")

  const [thanks, setThanks] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [thanks])

  const submitContactForm = event => {
    event.preventDefault()

    if(optoutsalerequest !== true ||  optoutsharerequest !== true){
       alert('Please select at least one checkbox: I would like to');
       return false;
    }

    const url = "https://7vtrzwg0yg.execute-api.us-west-2.amazonaws.com/isn-www-ccpa-request?currently_reside_state="+selectResideState+"&opt_out_of_the_sale_of_my_personal_information="+optoutsalerequest+"&opt_out_of_the_sharing_of_my_personal_information="+optoutsharerequest+"&first_name="+firstname+"&last_name=" +lastname + "&email_address=" + email + "&street_address=" + street_address + "&unit=" + unit +"&city=" + city + "&state=" + state + "&country=" + country + "&zip=" + zip + "&request=ccpa_do_not_sell_or_share"

    let encodedurl = encodeURI(url)

    fetch(encodedurl).then(function () {
      setThanks(request)
    })
  }

  return (
    <div>
      <Helmet
        title="Data Rights Request Form – do not sell or share"
        defer={false}
      />
      <Container>
        <Header />
      </Container>
        <Container className={'legacy-page-spacer'} fluid>
        <Row style={{ paddingTop: "100px" }}>
          <Col sm={12}>
            <h1
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Data Rights Request Form – do not sell or share
            </h1>
          </Col>
          {!thanks ? (
            <Col sm={12}>
              <br />
              <div className="entry-content-wrapper clearfix">
                <p style={{ fontSize: "110%" }}>
                  If you are a resident of certain states, you can opt out of the sale or sharing of your personal information by providing the following information. Please note that you will not be opted out from transactions that the state's law does not deem to be sales. Requests may be made only by or on behalf of a current resident of California, Connecticut (effective July 1, 2023), Virginia (effective January 1, 2023) or Colorado (effective July 1, 2023).
                </p>
                <p style={{ fontSize: "110%" }}>
                  In order to deliver relevant personalized ads to you, we use and may share with third parties your personal information. Even though they involve no exchange of money, these disclosures may constitute “sharing” or “selling” of personal information under certain U.S. state privacy laws, which we describe in more detail <a href="/privacy/">here</a>
                </p>
                <p style={{ fontSize: "110%" }}>
                  Certain residents may opt out of our sharing of your personal information for these personalized advertising purposes by clicking the opt out button below. If you opt out, we will cease this sharing and “targeted advertising” as defined under relevant state privacy laws.
                </p>
                <p style={{ fontSize: "110%" }}>
                  Please submit the following information to opt out of the sale or sharing of your personal information.

                </p>
              </div>
              <Form onSubmit={submitContactForm}>
                <br />
                <div>
                <label style={{ fontSize: "110%", fontWeight: "bold" }} htmlFor="selectState">I currently reside in the selected state … * </label>
                  <br />
                  <select 
                    required
                    style={{ padding: "10px 5px", width: "33%" }}
                    id="selectResideState"
                    onBlur={e => setResideState(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <br/>

                <p> <span style={{ fontSize: "110%", fontWeight: "bold" }}>
                  I would like to...*</span> and insert box with two choices</p>

                <div
                  aria-checked
                  role="checkbox"
                  className="k-form-field"
                  defaultValue={request}
                >
                  <input
                    style={{ margin: "5px 10px" }}
                    type="checkbox"
                    id="checkbox1"
                    className=""
                    value="1"
                    checked={optoutsalerequest}
                    onChange={optOutSaleRequest}

                  />
                  <label htmlFor="checkbox1" className="">
                    {" "}
                    Opt out of the sale of my personal information 
                  </label>
                  <br />
                  <input
                    style={{ margin: "5px 10px" }}
                    type="checkbox"
                    id="checkbox2"
                    className=""
                    value="{optoutsharerequest}"
                    onChange={optOutShareRequest}

                  />
                    <label htmlFor="checkbox2" className="">
                    {" "}
                    Opt out of the sharing of my personal information
                  </label>
                </div>

                <br />
                <p style={{ fontSize: "110%", fontWeight: "bold" }}>
                  Your information:
                </p>
                <div>
                  <Form.Group controlId="firstname">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control
                      required
                      autocomplete="off"
                      type="text" style={{ width:"33%" }}
                      defaultValue={firstname}
                      onBlur={e => setFirstname(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="lastname">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control
                      required
                      autocomplete="off"
                      type="text" style= {{ width:"33%" }}
                      defaultValue={lastname}
                      onBlur={e => setLastname(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Email address *</Form.Label>
                    <Form.Control
                      type="email" 
                      required
                      autocomplete="off"
                      style={{ width:"33%" }}
                      defaultValue={email}
                      onBlur={e => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="address">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      required
                      autocomplete="off"
                      type="text" style={{ width:"33%" }}
                      defaultValue={street_address}
                      onBlur={e => setAddress(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="unit">
                    <Form.Label>Units</Form.Label>
                    <Form.Control
                      type="text" style={{ width:"33%" }}
                      defaultValue={unit}
                      onBlur={e => setUnit(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text" style={{ width:"33%" }}
                      defaultValue={city}
                      onBlur={e => setCity(e.target.value)}
                    />
                  </Form.Group>

                <label htmlFor="countrySelect">Country of Residence</label>
                <br />
                <select
                  style={{ padding: "10px 5px", width: "33%" }}
                  id="countrySelect"
                  onBlur={e => setCountry(e.target.value)}
                  onChange={e => setCountry(e.target.value)}
                >
                  <option value="Country of Residence">---</option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Afganistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bonaire">Bonaire</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Ter">
                    British Indian Ocean Ter
                  </option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="SelectCanary Islands">Canary Islands</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Channel Islands">Channel Islands</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos Island">Cocos Island</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote DIvoire">Cote DIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaco">Curacao</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands">Falkland Islands</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Ter">
                    French Southern Ter
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Great Britain">Great Britain</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hawaii">Hawaii</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="India">India</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea North">Korea North</option>
                  <option value="Korea Sout">Korea South</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Nambia">Nambia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherland Antilles">
                    Netherland Antilles
                  </option>
                  <option value="Netherlands">
                    Netherlands (Holland, Europe)
                  </option>
                  <option value="Nevis">Nevis</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau Island">Palau Island</option>
                  <option value="Palestine">Palestine</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Phillipines">Philippines</option>
                  <option value="Pitcairn Island">Pitcairn Island</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Montenegro">
                    Republic of Montenegro
                  </option>
                  <option value="Republic of Serbia">Republic of Serbia</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="St Barthelemy">St Barthelemy</option>
                  <option value="St Eustatius">St Eustatius</option>
                  <option value="St Helena">St Helena</option>
                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                  <option value="St Lucia">St Lucia</option>
                  <option value="St Maarten">St Maarten</option>
                  <option value="St Pierre and Miquelon">
                    St Pierre and Miquelon
                  </option>
                  <option value="St Vincent and Grenadines">
                    St Vincent and Grenadines
                  </option>
                  <option value="Saipan">Saipan</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa American">Samoa American</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Is">
                    Turks and Caicos Is
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Erimates">
                    United Arab Emirates
                  </option>
                  <option value="United States of America">
                    United States of America
                  </option>
                  <option value="Uraguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Vatican City State">Vatican City State</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (Brit)">
                    Virgin Islands (Brit)
                  </option>
                  <option value="Virgin Islands (USA)">
                    Virgin Islands (USA)
                  </option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis and Futana Is">
                    Wallis and Futana Is
                  </option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <br />
                <br />

                <div
                  style={
                    country === "United States of America"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >

                <label htmlFor="selectState">State</label>
                  <br />
                  <select
                    style={{ padding: "10px 5px", width: "33%" }}
                    id="selectState"
                    onBlur={e => setState(e.target.value)}
                  >
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>


                  <br /><br/>
                  <Form.Group controlId="zip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text" style={{ width:"33%" }}
                    id="zip"
                    onBlur={e => setZip(e.target.value)}
                  />
                </Form.Group>

                </div>

                <Form.Group controlId="request">
                  <Form.Control
                    type="hidden"
                    defaultValue={request}
                    setRequest={e => setRequest(e.target.value)}
                  />
                </Form.Group>
                </div>

                <br /><br/>
                <Button
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </Col>
          ) : (
            <Col sm={12} style={{ textAlign: "center" }}>
              <br />
              <p style={{ fontSize: "120%" }}>
                Your request has been received! Please contact us at
                <a href="mailto:help@inspectionsupport.com"> help@inspectionsupport.com</a> if additional help is needed.
              </p>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default DoNotSellMyPersonalInformation
